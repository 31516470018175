<template>
  <div>
    <comparison-header></comparison-header>
    <v-row class="ml-13 mt-4" v-if="enabledFarm">
      <div class="title ml-6 py-1">
        {{ $t('Comparison.title') }}
      </div>

      <div class="headline ml-6 py-1">
        <v-btn
          data-id="go-back-button"
          text
          color="primary"
          @click="$router.go(-1)"
        >
          {{ $t('Comparison.back') }}
        </v-btn>
      </div>
    </v-row>
    <v-slide-group
      v-model="ultimaComparacaoAdicionada"
      class="mt-2"
      mandatory
      show-arrows
    >
      <template #prev>
        <v-row align="start" style="height: 100%">
          <v-row align="center" justify="end" style="height: 500px" no-gutters>
            <v-col class="text-center">
              <v-btn
                fab
                dark
                color="blue-grey darken-3 mr-2"
                height="40px"
                width="40px"
              >
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </template>

      <template #next>
        <v-row class="align-start" style="height: 100%">
          <v-row align="center" justify="end" style="height: 500px" no-gutters>
            <v-col class="text-center">
              <v-btn
                fab
                dark
                color="blue-grey darken-3 ml-2"
                height="40px"
                width="40px"
              >
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </template>

      <v-slide-item v-if="maximoPlanejamentos > 0">
        <div style="max-width: 100px; float: right">
          <div style="height: 190px; margin-bottom: 15px"></div>

          <div
            v-for="i in maximoPlanejamentos"
            :key="i"
            style="margin-bottom: 20px"
          >
            <div class="d-flex align-end" style="height: 202px; width: 100%">
              <v-card
                tile
                class="grey darken-3 white--text text-center"
                elevation="0"
                height="150px"
                width="100%"
              >
                <v-card-text
                  class="float-center text-center white--text pa-2"
                  style="height: 100%"
                >
                  <div class="title" style="line-height: 130px">{{ i }}ª</div>
                </v-card-text>
              </v-card>
            </div>

            <v-card
              :style="{ background: menuThemeBackground, marginTop: '49px' }"
              elevation="0"
            >
              <diseases-names></diseases-names>
            </v-card>
          </div>
        </div>
      </v-slide-item>

      <div v-for="(programa, index) in comparacaoProgramas" :key="index">
        <list-item
          v-if="programa.programa"
          :posicao="index"
          :program="programa.programa"
          :fazenda="programa.fazenda"
          :cultivar="programa.cultivar"
          @removerComparacao="removerComparacao"
        >
        </list-item>
      </div>
      <search
        :select-config="selectConfig"
        :fazendas="farms"
        :cultivares="selectCultivares"
        :programas="selectProgramas"
        @listaProgramas="listaProgramas"
        @listaCultivares="listaCultivares"
        @carregaProgramaSelecionado="carregaProgramaSelecionado"
      ></search>
    </v-slide-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LayoutMixin from '@/components/LayoutMixin.vue'
import DiseasesNames from '@/components/Spraying/DiseasesNames.vue'
import ComparisonHeader from '@/modules/comparison/components/Header.vue'
import ListItem from '@/modules/comparison/components/ListItem.vue'
import Search from '@/modules/comparison/components/Search.vue'

export default {
  name: 'ComparisonHome',

  mixins: [LayoutMixin],

  components: {
    ComparisonHeader,
    DiseasesNames,
    ListItem,
    Search,
  },

  data() {
    return {
      selectProgramas: [],
      selectCultivares: [],
      comparacaoProgramas: [],
      ultimaComparacaoAdicionada: 1,
      program: null,
      fazenda: null,
      selectConfig: {
        cultivar: {
          load: false,
        },
        programa: {
          load: false,
        },
        fazenda: {
          load: false,
        },
      },
    }
  },

  async mounted() {
    if (this.$route.query) {
      this.$root.$emit('loaderShow')

      const { programa_id, fazenda_id, cultivar_id } = this.$route.query

      await this.listaProgramas(Number(cultivar_id))

      await this.listaCultivares(fazenda_id)

      this.carregaProgramaSelecionado(
        Number(fazenda_id),
        Number(cultivar_id),
        Number(programa_id)
      )

      this.$root.$emit('loaderHide')

      this.resetSelects()
    }
  },

  computed: {
    ...mapGetters('crops', ['currentCrop']),
    ...mapGetters('farms', ['farms', 'enabledFarm']),
    ...mapGetters('seasonsCrops', ['activePeriod']),

    maximoPlanejamentos() {
      const programas = this.comparacaoProgramas.filter(
        ({ programa }) => programa || !programa?.planejamentos?.length
      )

      if (programas?.length <= 0) {
        return 0
      }

      const totais_aplicacoes = programas.map(({ programa }) => {
        return programa?.planejamentos?.length
      })

      return Math.max(0, ...totais_aplicacoes)
    },
    menuThemeBackground() {
      const grey = '#F8F9F8'
      return grey
    },
  },

  watch: {
    comparacaoProgramas(programs) {
      this.verifyProgramsWithWhiteMold(programs)
    },
  },

  methods: {
    ...mapActions('program', ['verifyProgramsWithWhiteMold']),

    resetSelects() {
      this.selectCultivares = []
      this.selectProgramas = []
    },

    async listaCultivares(id) {
      try {
        this.selectConfig.cultivar.load = true

        const response = await this.$api.cultivares.get(
          id,
          this.currentSeasonCrop?.id
        )
        const filteredByCrop = response.data.filter(
          (variety) => variety.crop_id == this.currentCrop.id
        )
        this.selectCultivares = filteredByCrop

        this.selectConfig.cultivar.load = false
      } catch (e) {
        this.selectConfig.cultivar.load = false
        this.$root.$emit('notify', 'error', this.$t('cultivar.listar.erro'))
      }
    },

    async listaProgramas(id) {
      try {
        this.selectConfig.programa.load = true

        const response = await this.$api.programas.findByCultivar(
          id,
          this.activePeriod.start_period,
          this.activePeriod.end_period
        )
        this.selectProgramas = response.data.filter(function (p) {
          return p.planejamentos.length > 0
        })

        this.selectConfig.programa.load = false
      } catch (err) {
        this.selectConfig.programa.load = false
        const message =
          err.response.data.message || this.$t('programa.carregar.erro_400')
        this.$root.$emit('notify', 'error', message)
      }
    },

    carregaProgramaSelecionado(fazendaId, cultivarId, programaId) {
      this.fazenda = this.buscaFazendaSelecionado(fazendaId)
      this.program = this.buscaProgramaSelecionado(programaId)
      this.cultivar = this.buscaCultivarSelecionado(cultivarId)

      if (this.validaProgramaComparacao(programaId)) {
        this.$root.$emit('notify', 'error', 'Programa já foi adicionado.')
        return null
      }

      const comparacao = {
        programa: this.program,
        cultivar: this.cultivar,
        fazenda: this.fazenda,
      }
      this.adicionaProgramaComparacao(comparacao)
    },
    buscaProgramaSelecionado(id) {
      return this.selectProgramas.find((programa) => programa.id == id)
    },
    buscaFazendaSelecionado(id) {
      return this.farms.find((fazenda) => fazenda.id === id)
    },
    buscaCultivarSelecionado(id) {
      return this.selectCultivares.find((cultivar) => cultivar.id === id)
    },
    validaProgramaComparacao(id) {
      return (
        this.comparacaoProgramas?.find(function (programa) {
          return programa?.programa?.id == id
        }) ?? false
      )
    },

    adicionaProgramaComparacao(comparacao) {
      this.comparacaoProgramas.push(comparacao)
      const indexDoUltimoAdicionado = this.comparacaoProgramas.length + 1
      this.ultimaComparacaoAdicionada = indexDoUltimoAdicionado
    },
    removerComparacao(posicao) {
      this.comparacaoProgramas.splice(posicao, 1)
    },
  },
}
</script>
