<template>
  <df-page-header
    :title="$t('Comparison.header.subtitle')"
    :subtitle="$t('Comparison.header.title')"
  >
  </df-page-header>
</template>

<script>
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'

export default {
  name: 'ComparisonHeader',

  components: {
    DfPageHeader,
  },
}
</script>
