<template>
  <v-slide-item d-inline-flex class="mx-2" v-slot="{ active, toggle }">
    <div>
      <v-card
        tile
        elevation="0"
        class="program-compare__info-block grey darken-1 center white--text mb-4"
        width="260px"
        @click="clickItem(active, toggle)"
      >
        <v-card-title class="pa-2">
          <v-spacer></v-spacer>
          <v-menu bottom left>
            <template #activator="{ on }">
              <v-btn dark icon v-on="on">
                <v-icon @click="removerComparacao(toggle)"
                  >mdi-close-circle-outline</v-icon
                >
              </v-btn>
            </template>
          </v-menu>
        </v-card-title>

        <v-card-text
          class="float-center text-center d-block white--text pa-2 mt-n12"
        >
          <div>{{ fazenda.name }}</div>
          <div>{{ cultivar.cultivar }}</div>
          <div class="title program-name">{{ program.nome }}</div>

          <div class="text--secundary">
            {{ $t('semeadura_em', [program.data_prevista.formatDate()]) }}
          </div>
          <div class="d-flex justify-center align-end">
            <v-chip
              small
              class="ma-2 caption white--text"
              color="red darken-1"
              v-if="program.severidade_id == 1"
            >
              <v-icon left small>mdi-weather-rainy</v-icon>
              {{ $t('severidade.alta') }}
            </v-chip>
            <v-chip
              small
              class="ma-2 caption"
              color="orange darken-1"
              v-else-if="program.severidade_id == 2"
            >
              <v-icon left small>mdi-weather-rainy</v-icon>
              {{ $t('severidade.media') }}
            </v-chip>
            <v-chip
              small
              class="ma-2 caption"
              color="yellow darken-1"
              v-else-if="program.severidade_id == 3"
            >
              <v-icon left small>mdi-weather-rainy</v-icon>
              {{ $t('severidade.baixa') }}
            </v-chip>

            <v-chip
              v-if="program.white_mold_enabled"
              color="#305082"
              x-small
              class="spraying-list__white-mold-label"
              >{{ $t('spraying.specific_white_mold_label') }}</v-chip
            >
          </div>
          <div class="text--secundary" v-if="program.sugestao_pulverizacoes">
            <span class="caption">
              {{ $t('pulverizacao.sugestao') }}:
              {{ program.sugestao_pulverizacoes }}
            </span>
          </div>

          <div class="text--secundary">
            <span class="title font-weight-bold pr-2">
              {{ $currency(custoTotal) }}
            </span>
            <span class="body-2"
              >{{ custoTotalSc }}
              {{ $unitMeasures['weight'][$currentLanguage()] }}</span
            >
          </div>
        </v-card-text>
      </v-card>

      <div v-for="planejamento in program.planejamentos" :key="planejamento.id">
        <v-card
          class="program-compare__products-block grey lighten-3 center white--text mt-4"
          height="200px"
          width="260px"
          @click="toggle"
        >
          <v-card-title class="pa-2 blue-grey">
            <div
              v-if="planejamento.data_aplicacao_planejada"
              class="body-2 ml-2"
            >
              {{ aplicacaoDataRange(planejamento) }}
            </div>
            <v-spacer></v-spacer>

            <v-menu bottom left> </v-menu>
          </v-card-title>

          <v-card-text
            class="float-center text-center d-block gray--text text--darken-2"
          >
            <v-row align="center" class="pl-2 pr-6">
              <v-col
                v-for="produto in planejamento.planejamento_defensivos.slice(
                  0,
                  3
                )"
                :key="produto.id"
                cols="6"
              >
                <div>
                  <div class="font-weight-bold" v-if="produto.defensivo">
                    {{ produto.defensivo.nome }}
                  </div>
                  <div class="caption" v-if="produto.defensivo">
                    {{ parseFloat(produto.dose).toFixedNotRounded(2) }}
                    {{ produto.defensivo.unidade_medida.replace('_', ' ') }}
                  </div>
                  <div class="font-weight-bold" v-else>
                    {{ $t('desconhecido') }}
                  </div>
                </div>
              </v-col>

              <!-- Se houver mais de 4 produtos, mostra quantos produtos não couberam na tela 
                    e coloca tooltip para mostrar eles -->
              <v-col
                v-if="planejamento.planejamento_defensivos.length > 4"
                cols="6"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <div v-on="on" class="title font-weight-bold">
                      + {{ planejamento.planejamento_defensivos.length - 3 }}
                    </div>
                  </template>

                  <div
                    v-for="produto in planejamento.planejamento_defensivos.slice(
                      3
                    )"
                    :key="'tooltip_' + produto.id"
                  >
                    {{ produto.defensivo.nome }}
                    ( {{ parseFloat(produto.dose).toFixedNotRounded(2)
                    }}{{ produto.defensivo.unidade_medida.replace('_', ' ') }} )
                  </div>
                </v-tooltip>
              </v-col>

              <!-- Senão, mostra o quarto produto -->
              <v-col
                v-else-if="planejamento.planejamento_defensivos.length === 4"
                cols="6"
              >
                <div class="font-weight-bold">
                  {{ planejamento.planejamento_defensivos[3].defensivo.nome }}
                </div>
                <div class="caption">
                  {{
                    parseFloat(
                      planejamento.planejamento_defensivos[3].dose
                    ).toFixedNotRounded(2)
                  }}
                  {{
                    planejamento.planejamento_defensivos[3].defensivo
                      .unidade_medida
                  }}
                </div>
              </v-col>
            </v-row>
            <v-chip
              v-if="specificWhiteMoldSpraying(planejamento)"
              color="#305082"
              x-small
              class="spraying-list__white-mold-label -absolute"
              >{{ $t('spraying.specific_white_mold_label') }}</v-chip
            >
          </v-card-text>
        </v-card>

        <v-row
          class="program-compare__price-block"
          justify="center"
          align="center"
        >
          <v-col></v-col>
          <v-col cols="5" class="body-1 font-weight-bold text-right pr-1">{{
            $currency(custoPlanejamento(planejamento))
          }}</v-col>
          <v-col cols="4" class="body-2 text-left"
            >{{ custoPlanejamentoSc(planejamento) }}
            {{ $unitMeasures['weight'][$currentLanguage()] }}</v-col
          >
          <v-col></v-col>
        </v-row>

        <v-row
          class="program-compare__efficacies-block"
          justify="center"
          no-gutters
        >
          <v-col>
            <efficacies :spraying="planejamento" hide-labels is-planning />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-slide-item>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { convertToTargetUnit } from '@/components/Form/converter'
import { currentLanguage, LANG } from '@/language'
import Efficacies from '@/components/Efficacies/index.vue'

export default {
  components: {
    Efficacies,
  },

  props: {
    posicao: { type: Number, required: true },
    program: { type: Object, required: true },
    fazenda: { type: Object, required: true },
    cultivar: { type: Object, required: true },
  },

  computed: {
    ...mapGetters('crops', ['currentCrop']),

    custoTotalSc() {
      return convertToTargetUnit(this.custoTotal / this.bagPrice, 'weight')
    },

    bagPrice() {
      const bag = this.fazenda?.bag_price?.find(
        (bag) => bag.crop_id === this.currentCrop.id
      )
      return bag?.price ?? 0
    },

    custoTotal() {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const pegaPrecos = (p) => p.planejamento_defensivos.map(precoFloat)
      const flatten = (acumulador, precos) => acumulador.concat(precos)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return this.program.planejamentos
        .map(pegaPrecos)
        .reduce(flatten, [])
        .reduce(somaTudo, 0)
        .toFixed(2)
    },
  },

  methods: {
    aplicacaoDataRange(planejamento) {
      const dateFormat = currentLanguage() === LANG.EN ? 'MM/DD' : 'DD/MM'
      const dataMin = moment(planejamento.data_aplicacao_planejada)
        .subtract(process.env.VUE_APP_DATA_APLICACAO_PERIODO_TOLERANCIA, 'days')
        .format(dateFormat)

      const dataMax = moment(planejamento.data_aplicacao_planejada)
        .add(process.env.VUE_APP_DATA_APLICACAO_PERIODO_TOLERANCIA, 'days')
        .format(dateFormat)

      return this.$t('_to_', [dataMin, dataMax])
    },

    custoPlanejamento(planejamento) {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return planejamento.planejamento_defensivos
        .map(precoFloat)
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    custoPlanejamentoSc(planejamento) {
      return convertToTargetUnit(
        this.custoPlanejamento(planejamento) / this.bagPrice,
        'weight'
      )
    },

    removerComparacao(toggle) {
      this.$emit('removerComparacao', this.posicao)
      toggle()
    },
    clickItem(toggle) {
      toggle()
    },
    specificWhiteMoldSpraying(planning) {
      return planning.doenca_prioritaria_id == 13
    },
  },
}
</script>

<style scoped lang="scss">
.program-name {
  font-size: 0.8rem !important;
}

.spraying-list__white-mold-label {
  text-transform: uppercase;
  font-weight: 600;
  bottom: 12px;
  right: 12px;
  color: white;

  &.-absolute {
    position: absolute;
  }
}
</style>
